<template>
	<div class="mywz_publish width_1200 margin_auto justify_content_sb">
		<div class="mywz_publish_header">
			<div class="mywz_publish_header_title">文章发布</div
			><div class="mywz_publish_header_tip pointer">
				<span>投诉须知</span>
				<img
					src="https://zy.metaera.media/assets/images/my/mywz_publish_tip.png"
				/>
			</div>
		</div>
		<div class="mywz_publish_title">
			<div class="mywz_publish_title_point">*</div>
			<div class="mywz_publish_title_text">
				<input
					class="mywz_publish_title_input"
					v-model="formData.title"
					placeholder="请输入标题，1-40个字"
					:maxlength="40"
					@input="onInput($event)"
				/>
			</div>
			<div class="mywz_publish_title_length"
				>{{ formData.title.length }}/40</div
			>
		</div>
		<div class="mywz_publish_content">
			<el-upload
				id="upimg"
				v-show="false"
				class="upload-demo"
				:disabled="$route.query.type == 'detail'"
				action="/upload"
				:before-upload="beforeUpload"
				multiple
			>
				<el-button size="small" type="primary">点击上传</el-button>
			</el-upload>
			<quill-editor
				:options="editorOption"
				class="ql-editor"
				ref="QuillEditor"
				:disabled="$route.query.type == 'detail'"
				v-model="formData.content"
				@blur="onEditorBlur($event)"
				@focus="onEditorFocus($event)"
				@ready="onEditorReady($event)"
			/>
		</div>
		<div class="mywz_publish_illustrate flex">
			<div class="mywz_publish_content_left mywz_publish_content_left_1 flex">
				<div class="mywz_publish_title_point">*</div>
				<div class="mywz_publish_title_lefttitle">文章摘要:</div>
			</div>
			<div class="mywz_publish_content_right">
				<div class="mywz_publish_illustrate_box">
					<textarea
						class="mywz_publish_illustrate_input"
						style="resize: vertical"
						v-model="formData.illustrate"
						:placeholder="'一段话描述文章内容，突出核心观点，必填项，文字需不超过80个字。'"
						:maxlength="80"
						:rows="3"
					></textarea>
				</div>
			</div>
		</div>
		<div class="mywz_publish_category flex">
			<div class="mywz_publish_content_left flex">
				<div class="mywz_publish_title_point">*</div>
				<div class="mywz_publish_title_lefttitle">专栏目录:</div>
			</div>
			<div class="mywz_publish_content_right">
				<div>
					<el-select
						class="mywz_publish_category_box"
						v-model="formData.category_id"
						autocomplete="off"
						filterable
					>
						<el-option
							v-for="category in categoryList"
							:key="category.id"
							:label="category.name"
							:value="category.id"
						>
						</el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="mywz_publish_pic flex">
			<div class="mywz_publish_content_left flex">
				<div class="mywz_publish_title_point">*</div>
				<div class="mywz_publish_title_lefttitle">上传封面:</div>
			</div>
			<div class="mywz_publish_content_right">
				<!-- 剪裁组件弹窗 -->
				<el-dialog
					title="裁切图片"
					:visible.sync="cropperModel"
					width="1300px"
					center
					:append-to-body="true"
				>
					<template v-if="cropperModel">
						<cropper-image-new
							:Name="cropperName"
							:type="scale"
							:showAvator="false"
							@uploadImgSuccess="handleUploadSuccess"
							ref="child"
						>
						</cropper-image-new
					></template>
				</el-dialog>
				<div class="mywz_publish_pic_box pointer" @click="uploadPicture()">
					<img
						v-if="formData.pic.length > 0"
						class="mywz_publish_pic_box_urlImg"
						:src="formData.pic"
					/>
					<div v-else
						><img
							class="mywz_publish_pic_box_img"
							src="https://zy.metaera.media/assets/images/my/mywz_add.png"
					/></div>
				</div>
				<div class="mywz_publish_pic_tip">
					<p>优质的封面有利于推荐，请使用清晰度较高的图片，建议尺寸：600*371</p>
					<p>图片格式支持:jpg、jpeg、png,图片大小限制: 不超过5M</p>
				</div>
			</div>
		</div>
		<div class="mywz_publish_source flex">
			<div class="mywz_publish_content_left flex">
				<div class="mywz_publish_title_point">*</div>
				<div class="mywz_publish_title_lefttitle">文章类型:</div>
			</div>
			<div class="mywz_publish_content_right">
				<div class="mywz_publish_source_box">
					<input
						type="radio"
						name="source_type"
						value="0"
						v-model="formData.source_type"
						:checked="formData.source_type == 0"
					/>
					声明原创
					<input
						type="radio"
						name="source_type"
						value="999"
						v-model="formData.source_type"
						:checked="formData.source_type == 999"
					/>
					转载
				</div>
			</div>
		</div>
		<div class="mywz_publish_sourceName flex">
			<div class="mywz_publish_content_left mywz_publish_content_left_1 flex">
				<div class="mywz_publish_title_point" v-if="formData.source_type == 999"
					>*</div
				>
				<div class="mywz_publish_title_lefttitle">文章来源:</div>
			</div>
			<div class="mywz_publish_content_right">
				<div class="mywz_publish_sourceName_box"
					><input
						class="mywz_publish_sourceName_input"
						v-model="formData.source_name"
						placeholder="请输入文章来源"
				/></div>
			</div>
		</div>
		<div class="mywz_publish_url flex">
			<div class="mywz_publish_content_left mywz_publish_content_left_1 flex">
				<div class="mywz_publish_title_point" v-if="formData.source_type == 999"
					>*</div
				>
				<div class="mywz_publish_title_lefttitle">文章链接:</div>
			</div>
			<div class="mywz_publish_content_right"
				><div class="mywz_publish_sourceName_box"
					><input
						class="mywz_publish_sourceName_input"
						v-model="formData.url"
						placeholder="请输入以 http:// 开头的文章链接" /></div
			></div>
		</div>
		<div class="mywz_publish_btn_box flex">
			<div class="mywz_publish_btn_submit pointer" @click="onSubmit()">
				提交审核
			</div>
			<div class="mywz_publish_btn_draft pointer" @click="onDraft()">
				存草稿
			</div>
			<div class="mywz_publish_btn_preview pointer" @click="onPreview()">
				预览
			</div>
		</div>
	</div>
</template>

<script>
import { getCategoryList, addInformation } from "@/api/article";
import { getInformationDetails } from "@/api/home";
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";

Vue.use(VueQuillEditor /* { default global options } */);
import Quill from "quill";
// require styles 导入富文本编辑器对应的样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 自定义字号的大小
var sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
var Size = Quill.import("attributors/style/size");
Size.whitelist = sizes;
Quill.register(Size, true);

const toolbarOptions = [
	["bold", "italic", "underline", "strike"], // toggled buttons
	["blockquote", "code-block"],
	[{ header: 1 }, { header: 2 }], // custom button values
	[{ list: "ordered" }, { list: "bullet" }],
	[{ size: Size.whitelist }], // 配置字号
	[{ header: [1, 2, 3, false] }],
	[{ color: [] }], // dropdown with defaults from theme
	[{ align: [] }],
	["link", "image", "video"],
	["clean"], // remove formatting button
];
export default {
	name: "",
	props: ["id"],
	data() {
		return {
			id: this.$route.query.id,
			errorShow: false,
			abstract: "",
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
			categoryList: [],
			informationDetails: {},
			formData: {
				title: "",
				content: "",
				illustrate: "",
				category_id: "",
				pic: "",
				source_type: "0",
				source_id: "1024",
				source_name: "",
				url: "",
				type: 5,
				score: 1,
				language: 1,
				examine_type: 2,
			},
			editorOption: {
				theme: "snow",
				modules: {
					toolbar: {
						container: toolbarOptions, // 工具栏
						handlers: {
							image: function (value) {
								if (value) {
									// 调用element的图片上传组件
									// （这里是用的原生js的选择dom方法）
									document.querySelector("#upimg button").click();
									// this.uploadPicture("");
								} else {
									this.quill.format("image", false);
								}
							},
						},
					},
				},
			},
		};
	},
	created() {
		this.getCategoryListFun();
		this.id = this.$route.query.id;
		if (this.id != null && this.id != "") {
			this.getInformationDetails();
		}
	},
	methods: {
		onInput(e) {
			this.formData.title = e.target.value;
		},
		onSubmit() {
			this.formData.examine_type = 2;
			// console.log(this.formData);
			this.publish(0);
		},
		onDraft() {
			this.formData.examine_type = 4;
			this.publish(0);
		},
		async getInformationDetails() {
			await getInformationDetails({ id: this.id })
				.then((res) => {
					if (res.code == 200) {
						this.informationDetails = res.data;
						this.formData.title = this.informationDetails.title;
						this.formData.content = this.informationDetails.content;
						this.formData.illustrate = this.informationDetails.illustrate;
						this.formData.pic = this.informationDetails.pic;
						this.formData.source_type = this.informationDetails.source_type;
						this.formData.source_name = this.informationDetails.source_name;
						this.formData.url = this.informationDetails.url;
						this.formData.source_id = "1024";
						this.formData.category_id =
							this.informationDetails.category_id + "";
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
			this.$loading.hide();
		},

		publish(status) {
			if (this.id != null && this.id != "") {
				this.formData.id = this.id;
			}
			if (this.formData.title == "") {
				this.$message.error("请输入标题");
				return;
			}
			if (this.formData.content == "") {
				this.$message.error("请输入内容");
				return;
			}
			if (this.formData.pic == "") {
				this.$message.error("请上传封面");
				return;
			}
			if (this.formData.source_type == 999 && this.formData.url == "") {
				this.$message.error("请输入原文链接");
				return;
			}
			if (this.formData.source_type == 999 && this.formData.source_name == "") {
				this.$message.error("请输入文章来源");
				return;
			}
			if (this.formData.category_id == "") {
				this.$message.error("请选择专栏目录");
				return;
			}

			let that = this;
			addInformation(this.formData)
				.then((res) => {
					if (res.code == 200) {
						if (status == 1) {
							that.$router.push({
								path: "/gw_detail",
								query: { id: res.data },
							});
						} else {
							that.$message.success("提交成功");
							this.$emit("gotoManage");
						}
					} else {
					}
				})
				.catch((error) => {});
		},

		onPreview() {
			this.formData.examine_type = 4;
			this.publish(1);
		},
		// 资讯分类列表
		getCategoryListFun() {
			const that = this;
			getCategoryList({ size: 100, type: 5 })
				.then((res) => {
					if (res.code == 200) {
						that.categoryList = res.data;
						that.categoryList.forEach((item) => {
							item.id = item.id.toString();
						});
					} else {
						that.categoryList = [];
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		//封面设置
		uploadPicture(name) {
			this.cropperName = name;
			this.type = "1";
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			// console.log(data.url);
			this.cropperModel = false;
			if (
				this.cropperName != undefined &&
				this.cropperName.indexOf("blob:http") != -1
			) {
				this.handleSuccess(data);
			} else {
				this.formData.pic = data.url;
			}
		},
		// 上传切图前调用
		beforeUpload(file) {
			this.cropperName = URL.createObjectURL(file);
			this.type = "0";
			this.cropperModel = true;
			return false;
		},
		handleSuccess(data) {
			// 获取富文本组件实例
			let quill = this.$refs.QuillEditor.quill;
			if (data) {
				// 如果上传成功
				// 获取光标所在位置
				// let length = quill.getSelection().index;
				let length = 0;
				if (quill.getSelection() && quill.getSelection().index) {
					length = quill.getSelection().index;
				} else {
					length = 0;
				}
				// 插入图片，res为服务器返回的图片链接地址
				quill.insertEmbed(length, "image", data.url);
				// 调整光标到最后
				quill.setSelection(length + 1);
			} else {
				// 提示信息
				this.$message.error("图片插入失败");
			}
		},
	},
};
</script>
<style scoped>
.mywz_publish {
	border-radius: 20px;
	opacity: 1;

	background: #ffffff;

	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
	width: 920px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.mywz_publish_header {
	text-align: center;
	position: relative;
	margin-top: 5px;
}

.mywz_publish_header_title {
	height: 28px;
	font-size: 20px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
}

.mywz_publish_header_tip {
	text-align: right;
	margin-top: -4px;
}

.mywz_publish_header_tip span {
	color: #0056ff;
	font-size: 12px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
}
.mywz_publish_header_tip img {
	width: 16px;
	height: 16px;
	margin-left: 2px;
}

.mywz_publish_title {
	height: 40px;
	border-bottom: 1px solid #e5e5e5;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 7px 7px 7px 7px;
	opacity: 1;
	border: 1px solid #dcdcdc;
	margin-top: 8px;
	margin-bottom: 12px;
}
.mywz_publish_title_point {
	color: #ff0000;
}
.mywz_publish_title_text {
	width: 760px;
}
.mywz_publish_title_input {
	width: 100%;
	flex: 1;
	border-radius: 7px 7px 7px 7px;
	opacity: 1;
	border: 1px solid #ffffff;
	margin-left: 6px;
}
.mywz_publish_title_length {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #999999;
}
.mywz_publish_title_lefttitle {
	width: 70px;
	height: 22px;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #333333;
	margin-left: 6px;
}
.mywz_publish_content_left {
	width: 85px;
	margin-top: 20px;
}
.mywz_publish_content_left_1 {
	margin-top: 25px !important;
}
.mywz_publish_content_right {
	margin-left: 37px;
	padding-top: 12px;
	padding-bottom: 12px;
}
.mywz_publish_illustrate_box {
	width: 680px;
}
.mywz_publish_illustrate_input {
	width: 100%;
	height: 104px;
	background: #ffffff;
	border-radius: 7px 7px 7px 7px;
	opacity: 1;
	border: 1px solid #dcdcdc;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
}
.mywz_publish_category_box {
	width: 710px;
}
.mywz_publish_pic_box {
	width: 270px;
	height: 170px;
	background: rgba(242, 242, 242, 0.6);
	border-radius: 8px 8px 8px 8px;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
	margin-bottom: 13px;
}
.mywz_publish_pic_box_img {
	width: 25px;
	height: 25px;
}
.mywz_publish_pic_box_urlImg {
	width: 100%;
	height: 100%;
}
.mywz_publish_pic_tip {
	margin-bottom: 0;
}
.mywz_publish_pic_tip p {
	font-size: 14px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #000000;
	margin-bottom: 24px;
}
.mywz_publish_source_box {
	margin-top: 5px;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #333333;
}
.mywz_publish_sourceName_box {
	/* margin-top: 5px; */
	width: 710px;
	height: 46px;
	background: #ffffff;
	border-radius: 7px 7px 7px 7px;
	opacity: 1;
	border: 1px solid #dcdcdc;
}
.mywz_publish_sourceName_input {
	margin-left: 16px;
	width: 690px;
	height: 44px;
	border-radius: 0;
	opacity: 1;
	border: 0px solid #fff;
}
.mywz_publish_btn_box {
	margin-top: 25px;
	margin-bottom: 146px;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
}
.mywz_publish_btn_submit {
	width: 133px;
	height: 44px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 6px 6px 6px 6px;
	opacity: 1;
	border: 1px solid #0056ff;
	color: #ffffff;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
	margin-right: 20px;
	margin-left: 20px;
}
.mywz_publish_btn_draft,
.mywz_publish_btn_preview {
	width: 122px;
	height: 44px;
	background: #ffffff;
	border-radius: 5px 5px 5px 5px;
	opacity: 1;
	border: 1px solid #e1e1e1;
	color: #999999;
	font-size: 16px;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	display: flex;
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
	margin-right: 20px;
	margin-left: 20px;
}
</style>
<style>
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	/* WebKit browsers */
	color: #c8c8c8;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #c8c8c8;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #c8c8c8;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: #c8c8c8;
	font-family: PingFang SC, "PingFang SC", "微软雅黑", "Helvetica Neue",
		"Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
}
</style>
<style>
.ql-snow .ql-picker {
	line-height: 24px;
}
.ql-container {
	height: 700px;
	overflow: auto;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
	content: "黑体";
	font-family: "SimHei";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Microsoft-YaHei"]::before {
	content: "微软雅黑";
	font-family: "Microsoft YaHei";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
	content: "楷体";
	font-family: "KaiTi";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
	content: "仿宋";
	font-family: "FangSong";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
	content: "Arial";
	font-family: "Arial";
}

.ql-snow
	.ql-picker.ql-font
	.ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
	.ql-picker.ql-font
	.ql-picker-item[data-value="Times-New-Roman"]::before {
	content: "Times New Roman";
	font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
	content: "sans-serif";
	font-family: "sans-serif";
}

.ql-font-SimSun {
	font-family: "SimSun";
}

.ql-font-SimHei {
	font-family: "SimHei";
}

.ql-font-Microsoft-YaHei {
	font-family: "Microsoft YaHei";
}

.ql-font-KaiTi {
	font-family: "KaiTi";
}

.ql-font-FangSong {
	font-family: "FangSong";
}

.ql-font-Arial {
	font-family: "Arial";
}

.ql-font-Times-New-Roman {
	font-family: "Times New Roman";
}

.ql-font-sans-serif {
	font-family: "sans-serif";
}

/* 字号设置 */
/* 默认字号 */
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: "14字号";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
	content: "14字号" !important;
	font-size: 14px;
}

.ql-size-14px {
	font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
	content: "16字号";
	font-size: 16px;
}

.ql-size-16px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
	content: "18字号";
	font-size: 16px;
}

.ql-size-18px {
	font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
	content: "20字号";
	font-size: 16px;
}

.ql-size-20px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
	content: "22字号";
	font-size: 16px;
}

.ql-size-22px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
	content: "26字号";
	font-size: 16px;
}

.ql-size-26px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
	content: "28字号";
	font-size: 16px;
}

.ql-size-28px {
	font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
	content: "30字号";
	font-size: 16px;
}

.ql-size-30px {
	font-size: 16px;
}
.quill-editor {
	padding: 0px 0px !important;
}
.ql-editor {
	padding: 0px 0px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
}
</style>
<style></style>
